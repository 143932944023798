.largeHeader,
.largeBtuuom {
  height: 50px;
  background: blue;
  position: fixed;
  left: 5px;
  right: 5px;
}
.largeHeader {
  top: 5px;
}
.largeBtuuom {
  bottom: 5px !important;
}
.large {
  width: 100%;
  background: url("../../../static/image/bodybg.png");
  background-size: cover;
}
