* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li {
  list-style: none;
}
/* 设置字体 */
@font-face {
  font-family: electronicFont;
  src: url(./static/font/PMZDBTT.TTF);
}
/* 规定颜色 */
body {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
@media screen and (max-width: 1024px) {
  html {
    /* font-size: 42px !important; */
  }
}
@media screen and (min-width: 1920) {
  html {
    /* font-size: 80px !important; */
  }
}
