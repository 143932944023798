.column{
    &__conten{
        display: flex;
        // align-items: center;
        justify-content: space-between;
        >div{
            width: 423px;
        }
    }
    &__pole{
        width: 100%;
        height: 700px;
        display: flex;
        align-items: center;
        position: relative;
        justify-content: center;
    }
    &__wrj{
        position: absolute;
        left: 30%;
        height: 8%;
        width: 8%;
        animation: identifier 5s infinite;
    }
    &__wifi{
        position: absolute;
        width: 5%;
        height: 8%;
        top: 8%;
        right: 28%;
    }
    &__wind_shape{
        width: 10%;
        height: 18%;
        position: absolute;
        left: 8%;
        top: 28%;
    }
    &__sun{
        position: absolute;
        width: 100px;
        height: 100px;
        top: 8%;
        left: 20%;
        animation:mymove 3s infinite;
    }
    &__landLevel{
        width: 90%;
        height: 80%;
    }
    &__statistics{
        background: url('../../../../static/image/banner.png');
        background-size: cover;
        height: 34px;
        width: 100%;
        margin: 12px 0;
        >div{
            padding-left: 65px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 100%;
            color: #B0D8F5;
            >span{
                font-size: 13px;
            }
            >div{
                font-size: 13px;
                color: #99B3C8;
                >span{
                    font-size: 20px;
                    font-family: electronicFont;
                    display: inline-block;
                    padding-right: 4px;
                    color: #B0D8F5;
                }
            }
        }
    }
}
@keyframes identifier {
    0%{
        left: 250px;
        top: 0;
    }
    25%{
        left: 300px;
        top: 0;
    }
    50%{
        left: 150px;
        top: 300px;
    }
    75%{
        left: 250px;
        top: 400px;
    }
    100%{
        left: 250px;
        top: 20px;
    }
}
@keyframes mymove{
    0%{
        transform: scale(1);
    }
    25%{
        transform: scale(1.1);

    }
    50%{
        transform: scale(1.1);
    }
    75%{
        transform: scale(1);
    }
}
#monitor{
    width: 420px;
    height: 200px;
}
#energy{
    width: 420px;
    height: 195px;
}