.surfaceClass {
    width: 170px;
    height: 160px;
    margin: 0 auto;
}
.environment{
    width: 465px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px auto;
    font-size: 14px;
    color: #fff;
    &__box:last-child{
       
    }
    &__circle{
        width: 18px;
        height: 18px;
        >img{
            width: 100%;
            height: 100%;
        }
    }
    &__conten{
        display: flex;
        align-items: center;
        padding: 12px 20px;
        p{
            padding: 0 8px;
        }
    }
    &__tall{
        text-align: center;
        p{
            padding-top: 25px;
            color: #3F91D8;
            font-size: 14px;
        }
        span{
            font-size: 20px;
            display: inline-block;
            color: #fff;
            padding-top: 20px;
        }
    }
    &__time{
        font-size: 12px;
        color: #fff;
        padding-top: 30px;
        font-weight: bold;
        padding-left: 20px;
    }
    &__describe{
        font-size: 12px;
        color: #fff;
        font-weight: bold;
        p{
            padding: 5px 0 0 20px;
        }
    }
    &__header{
        display: flex;
        font-size: 14px;
        color: #fff;
        align-items: center;
        padding: 16px 0 8px 20px;
        >span{
            display: inline-block;
            padding-left: 8px;
            font-weight: bold;
        }
        &--icon{
            width: 18px;
            height: 18px;
            >img{
                width: 100%;
                height: 100%;
            }
        }
    }
    >div{
        width: 223px;
        height: 200px;
        background: url('../../../../static/image/imbg.png');
        background-size: cover;
        position: relative;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        >img{
            position: absolute;
            width: 100%;
            height: 100%;
        }
        >img:first-child{
            width: 90% !important;
        }
    }
    >div:first-child{
        
    }
}
.water{
    margin-top: 35px;
    &__box{
        display: flex;
        align-items: center;
        text-align: center;
        padding: 0 5px;
        font-size: 10px;
        >p{
            flex: 1;
        }
    }
    &__back{
        width: 100%;
        height: 45px;
        >img{
            width: 100%;
            height: 100%;
        }
    }
    &__zhizheng{
        background: url('../../../../static//image/zz.png');
        background-size: cover;
        width: 84px;
        height: 28px;
        position: relative;
        transition: 0.3s;
        left: -30px;
        // 每隔一个间距15
        p{
            color: #3F91D8;
            font-size: 12px;
            padding-top: 10px;
            text-align: center;
        }
    }
}
.soil{
    width: 175px;
    height: 123px;
    color: #fff;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: url('../../../../static/image/back.png');
    background-size: cover;
    position: relative;
    &__conten{
        position: absolute;
        top: 32px;
        left: 50px;
    }
    &__conten:last-child{
        top: 76px;
    }
    &__conten{
        h2{
            font-size: 18px;
            font-family: electronicFont;
            font-weight: bold;
        }
        p{
            font-size: 10px;
        }
    }
    &__solid{
        height: 1px;
        width: 64px;
        margin: 10px auto;
        background: #6E6E6E;
    }
}